import React from 'react';
import Header from '../../../Components/Header/Header';
import { Quru } from '../../../assets';
import { useTranslation } from 'react-i18next';

const OdenissizKonsultasiya = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <Header/>
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Gömrük rəsmiləşdirmələri üzrə ödənişsiz konsultasiya")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Biz müştərilərimizə beynəlxalq ticarət proseslərində maksimum dəstək vermək məqsədilə gömrük rəsmiləşdirmələri üzrə ödənişsiz konsultasiya xidməti təklif edirik. Bu xidmət vasitəsilə, gömrük rəsmiləşdirmələri ilə bağlı bütün suallarınıza cavab tapa, prosedurlar haqqında ətraflı məlumat əldə edə və sizin ticarət əməliyyatlarınıza ən uyğun həll yollarını öyrənə bilərsiniz. Peşəkar mütəxəssislərimiz, sizə gömrük qanunvericiliyi, tələb olunan sənədlər və rəsmiləşdirmə prosesləri barədə dərin bilik və təcrübələrini paylaşaraq, əməliyyatlarınızın daha sürətli və problemsiz şəkildə həyata keçməsinə kömək edəcək. Bizimlə əməkdaşlıq edərək, gömrük rəsmiləşdirmələrinizin doğru və effektiv şəkildə aparılmasından əmin ola bilərsiniz.")}
          </p>
          <a href='/konsultasiya'><button className='mt-2 px-8 py-2 bg-teal-500 text-white rounded-full'>Ödənişsiz konsultasiya</button></a>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
    </>
  );
}

export default OdenissizKonsultasiya;

import React from "react";
import { topdan } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";

const TopdanSatis = () => {

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Contrary to popular belief")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-base">
            {t("Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure")}
          </p>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
            {t("Latin words, consectetur, from a Lorem Ipsum")}
          </h1>
          <p className="text-sm md:text-base">
            {t("Lorum et Malorum' (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, 'Lorem ipsum dolor sit amet..', comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero")}
          </p> 
          <br/>
          <ul className="list-disc list-inside text-sm md:text-base">
            <li>{t("Ümumi yüklərin daşınması")}</li>
            <li>{t("Konteyner yüklərin daşınması")}</li>
            <li>{t("Qabaritə az olan yüklərin daşınması")}</li>
            <li>{t("Ağır çəkili yüklərin daşınması")}</li>
            <li>{t("Temperatura asılı olan yüklərin daşınması")}</li>
            <li>{t("'Qapıdan qapıya' yük daşima xidməti")}</li>
            <li>{t("Daxili yük daşima xidməti")}</li>
            <li>{t("Beynəlxalq yük daşima xidməti")}</li>
          </ul> 
          <br/>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
            {t("Lontrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a pie")}
          </h1>
          <p className="text-sm md:text-base">
            {t("Trade Group şirkəti Avropa (Almaniya, İtaliya, Fransa və s.), Asiya (Çin, Tayvan və s. yüklər), MDB (Rusiya, Belarusiya və s.) ölkələri arasında yüklərin avtomojnavt vasitələri ilə çatdırılmasını və ticarət əlaqələrinin təmin edilməsi üzrə ixtisaslaşmışdır")} 
            <br/> <br/>
            {t("Bütün bunlarla yanaşı Trade Group şirkəti olaraq həmçinin quru yük daşımaları zamanı tələb olunan hüquqi və gömrük sənədlərinin hazırlanmasında da tam şəkildə müştərilərimizə dəstək göstəririk. Eyni zamanda konsalting xidmətimizdən də yararlanmaqla öncədən quru üzərindən en effektiv yol xəritənizi də tərtib edə bilərsiniz.")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${topdan})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center', 
            backgroundRepeat:"no-repeat"
          }} 
        ></div>
      </div>
    </>
  );
};

export default TopdanSatis;

import React from "react";
import { Quru } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";

const Havayol = () => {

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Hava nəqliyyatı ilə yük daşımaları")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Yüklərin hava yolu ilə daşınması dünyanın hansı yerindən asılı olmayaraq yük çatdırılmasının ən sürətli və etibarlı yoludur. Bu baxımdan hava nəqliyyatı loqistik xidmətlər arasında ən vacib və tələb olunan xidmətlərdən biri hesab olunur. Trade Group şirkəti olaraq hava nəqliyyatı daşımaları üzrə sizə tam kompleks xidmət təklif edirik. Buraya avia daşınma xidmətlərindən tutmuş hüquqi və gömrük sənədləşdirilməsinə qədər tam xidmətlər paketi daxildir.")}
          </p>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
            {t("Hava nəqliyyatı xidmətinin coğrafiyası")}
          </h1>
          <p className="text-sm md:text-lg">
            {t("Bizim hava nəqliyyatı xidmətimizin coğrafiyası Cənub-Şərqi Asiya, Avropa, Yaxın Şərq, Şimali və Cənubi Amerikanı əhatə edir. Müştərinin ehtiyaclarından asılı olaraq, biz hava yolunu, marşrutu və çatdırılma vaxtını da təyin edə bilirik.")} <br/>
            {t("Trade Group şirkəti Lufthansa, LOT, FinnAir, Emirates, China Airlines, Aeroflot, AirBridge və bir çox digər beynəlxalq aviaşirkətlərin xidmətlərindən istifadə edir")}
          </p> 
          <br/>
          <ul className="list-disc list-inside text-sm md:text-base">
            <li className="text-[17px]">{t("Ümumi yüklərin daşınması")}</li>
            <li className="text-[17px]">{t("Konteyner yüklərin daşınması")}</li>
            <li className="text-[17px]">{t("Qabaritə az olan yüklərin daşınması")}</li>
            <li className="text-[17px]">{t("Ağır çəkili yüklərin daşınması")}</li>
            <li className="text-[17px]">{t("Temperatura asılı olan yüklərin daşınması")}</li>
            <li className="text-[17px]">{t("'Qapıdan qapıya' yük daşima xidməti")}</li>
            <li className="text-[17px]">{t("Daxili yük daşima xidməti")}</li>
            <li className="text-[17px]">{t("Beynəlxalq yük daşima xidməti")}</li>
          </ul>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364] mt-4">
            {t("Nəqliyyat vasitələrində malların daşınma marşrutları")}
          </h1>
          <p className="text-sm md:text-lg">
            {t("Trade Group şirkəti Avropa (Almaniya, İtaliya, Fransa və s.), Asiya (Çin, Tayvan və s. yüklər), MDB (Rusiya, Belarusiya və s.) ölkələri arasında yüklərin avtomojnavt vasitələri ilə çatdırılmasını və ticarət əlaqələrinin təmin edilməsi üzrə ixtisaslaşmışdır.Bütün bunlarla yanaşı Trade Group şirkəti olaraq həmçinin quru yük daşımaları zamanı tələb olunan hüquqi və gömrük sənədlərinin hazırlanmasında da tam şəkildə müştərilərimizə dəstək göstəririk. Eyni zamanda konsalting xidmətimizdən də yararlanmaqla öncədən quru üzərindən en effektiv yol xəritənizi də tərtib edə bilərsiniz.")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
    </>
  );
};

export default Havayol;

import React from "react";
import { multimodal } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";

const MultiModal = () => {

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Multimodal daşımalar")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Multimodal nəqliyyat bütün nəqliyyat infrastrukturundan istifadə etməklə bir neçə nəqliyyat növündə malların mərhələli şəkildə çatdırılmasıdır. Eyni zamanda, dəniz, çay, dəmir yolu, avtomobil və ya hava yolu ilə daşima üsulları istənilən kombinasiyada birləşdirilə bilər.")} <br />  <br /> 
            {t("Trade Group şirkəti daşima prosesində iştirak edən nəqliyyatın xüsusiyyətləri də daxil olmaqla, bütün detalları nəzərə alaraq multimodal daşımaları həyata keçirir. Bu, ən səmərəli çatdırılma sxemlərini hazırlamağa və etibarlılığı və son tarixlərə riayət etməyi təmin etmək üçün sərfəli həllər tapmağa imkan verir.")} <br /><br /> 
            {t("Trade Group şirkəti olaraq həm ölkə daxilı, həm də ölkə xarici multimodal daşima xidmətlərini təklif edirik. Eyni zamanda bu baxımdan peşəkar mütəxəssislərimizin konsultasiyasından da istədiyiniz vaxt yararlana bilərsiniz.")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${multimodal})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
    </>
  );
};

export default MultiModal;

import React from 'react';
import Header from "../../../Components/Header/Header";
import { useTranslation } from 'react-i18next';
import { Quru } from '../../../assets';

const BeyannameTertibi = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header/>
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Bəyannamələrin tərtibi")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Biz beynəlxalq ticarət əməliyyatlarınızın bütün mərhələlərində yanınızdayıq. Xüsusilə, bəyannamələrin tərtibi sahəsində peşəkar xidmətlər təqdim edirik. Bəyannamələrin düzgün və vaxtında tərtib edilməsi beynəlxalq ticarətdə kritik əhəmiyyət daşıyır. Bizim mütəxəssislər komandamız, bəyannamələrin beynəlxalq gömrük qanunvericiliyinə uyğun olaraq hazırlanmasını təmin edir, bu da sizin əməliyyatlarınızın ləngimədən həyata keçirilməsinə və əlavə xərclərin qarşısının alınmasına kömək edir. Bizimlə əməkdaşlıq edərək, bəyannamələrinizin doğru və effektiv şəkildə tərtib olunduğundan əmin ola bilərsiniz.")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
    </>
  );
}

export default BeyannameTertibi;

import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { carousel1, carousel2, carousel3 } from "../../assets";
import { t } from "i18next";

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: carousel1, text: "Tradegroup - yükünüzü etibar edəcəyiniz logistika şirkəti" },
    { img: carousel2, text: "YÜK DAŞINMASI" },
    { img: carousel3, text: "YÜK DAŞINMASI"},
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500);

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      onSlideChange={(swiper) => {
        setShowText(false);
        setShowButton(false);
        setActiveIndex(swiper.activeIndex);
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="relative">
            <img src={slide.img} alt={`Slide ${index + 1}`} className="w-full h-[60vh] sm:h-[70vh] lg:h-[90vh] object-cover" />
            {activeIndex === index && (
              <div className="absolute inset-0 flex flex-col justify-center items-center lg:items-start text-white p-4 lg:p-8 transition-opacity duration-1000 bg-black/50 lg:bg-blue-900/50">
                <div className="w-[90%] lg:w-[80%] mx-auto mb-6 lg:mb-14 text-center lg:text-left">
                  <h2 className={`text-2xl sm:text-4xl lg:text-7xl font-bold mb-4 lg:mb-10 transition-all text-center duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                    {t(slide.text)}
                  </h2>
                  <ul className={`text-center text-xs sm:text-sm lg:text-lg space-y-1 lg:space-y-2 transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                    <li className="text-[20px]">{t("SIĞORTALI")}</li>
                    <li className="text-[20px]">{t("TƏHLÜKƏSİZ")}</li>
                    <li className="text-[20px]">{t("PEŞƏKAR")}</li>
                    <li className="text-[20px]">{t("SÜRƏTLİ")}</li>
                  </ul>

                  <div className="flex flex-col lg:flex-row justify-center lg:justify-center mt-4 lg:mt-8 space-y-3 lg:space-y-0 lg:space-x-6">
                    <a href="/about">
                      <button className={`btn btn-primary px-4 py-2 sm:px-6 sm:py-3 lg:px-8 lg:py-4 border-2 border-white transition-all duration-1000 transform ${showButton ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                        {t("HAQQIMIZDA")}
                      </button>
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=994502613940&text=">
                      <button className={`btn btn-primary px-4 py-2 sm:px-6 sm:py-3 lg:px-8 lg:py-4 border-2 border-white transition-all duration-1000 transform ${showButton ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                        {t("ƏLAQƏ")}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;

import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css'; // Custom CSS for popups

// Marker icon settings
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const Map = () => {
  const locations = [
    { name: 'Shenzhen China', position: [22.5431, 114.0579] },
    { name: 'Yiwu China', position: [29.3064, 120.0818] },
    { name: 'Khorgos China', position: [43.3290, 81.3941] },
    { name: 'Baku Azerbaijan', position: [40.4093, 49.8671] },
    { name: 'Tbilisi Georgia', position: [41.7151, 44.8271] },
    { name: 'Almaty Kazakhstan', position: [43.2220, 76.8512] },
    { name: 'Aktau Kazakhstan', position: [43.6532, 51.1721] },
    { name: 'İpək yolu Gömrük', position: [40.4093, 49.8671] },
    { name: 'Alat Gömrük Postu', position: [40.5890, 49.6619] },
    { name: 'Poti Georgia', position: [42.1594, 41.6281] },
    { name: 'Istanbul Turkey', position: [41.0082, 28.9784] },
    { name: 'Ankara Turkey', position: [39.9334, 32.8597] },
    { name: 'Poland Warsaw', position: [52.2297, 21.0122] },
  ];

  return (
    <MapContainer center={[41.0082, 28.9784]} zoom={3} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>'
      />
      {locations.map((location, idx) => (
        <Marker key={idx} position={location.position}>
          <Popup>
            <div className="custom-popup">
              <h3>{location.name}</h3>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;




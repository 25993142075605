import React from 'react';
import Header from '../../../Components/Header/Header';
import { Quru } from '../../../assets';
import { useTranslation } from 'react-i18next';

const ResmilesdirmeIcaze = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <Header/>
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Rəsmiləşdirmə üzrə icazə sənədlərinin alınması")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Biz beynəlxalq ticarət proseslərinizin bütün hüquqi tələblərinə cavab verməsi üçün rəsmiləşdirmə üzrə icazə sənədlərinin alınması xidmətini də təmin edirik. İxrac və idxal əməliyyatlarınız zamanı tələb olunan bütün icazə sənədlərinin vaxtında və düzgün şəkildə əldə olunması, sizin əməliyyatlarınızın ləngimədən və qanun çərçivəsində aparılmasını təmin edir. Bizim mütəxəssislər komandamız, icazə sənədlərinin tələblərini, müraciət prosesini və digər zəruri addımları sizin adınıza həyata keçirir, beləliklə, siz əsas biznes fəaliyyətlərinizə fokuslana bilirsiniz. Rəsmiləşdirmə üzrə bütün proseslərin problemsiz keçməsi üçün bizə etibar edə bilərsiniz.")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
    </>
  );
}

export default ResmilesdirmeIcaze;









import React from 'react';
import { Anbar, Demir, Deniz, Gomruk, Hava, KompleksLogistika, Multimodal, Quru, Service5, Service6 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const transportMethods = [
  {
    title: "Quru yük daşımaları",
    image: Quru,
    path: '/quruyol',
     description: "A membership in FIATA and WCA, contracts with the leading shipping lines, terminals and overseas agents is a guarantee of high-quality service. "
  },
  {
    title: "Hava nəqliyyatı",
    image: Hava,
    path: '/havayol',
    description: "A membership in FIATA and WCA, contracts with the leading shipping lines, terminals and overseas agents is a guarantee of high-quality service. "
  },
  {
    title: "Dəmir yolu nəqliyyatı",
    image: Demir,
    path: '/demiryol',
    description: "A membership in FIATA and WCA, contracts with the leading shipping lines, terminals and overseas agents is a guarantee of high-quality service. "
  },
  {
    title: "Dəniz nəqliyyatı",
    image: Deniz,
    path: '/denizyol',
    description: "A membership in FIATA and WCA, contracts with the leading shipping lines, terminals and overseas agents is a guarantee of high-quality service. "
  },
  {
    title: "Gömrük rəsmiləşdirilməsi",
    image: Gomruk,
    path: '/gomruk-resmi',
    description: "A membership in FIATA and WCA, contracts with the leading shipping lines, terminals and overseas agents is a guarantee of high-quality service. "
  },
  {
    title: "Multimodal daşımalar",
    image: Quru,
    path: '/multimodal',
    description: "A membership in FIATA and WCA, contracts with the leading shipping lines, terminals and overseas agents is a guarantee of high-quality service. "
  },
  {
    title: "Anbar xidməti",
    image: Anbar,
    path: '/anbar-xidmeti',
    description: "A membership in FIATA and WCA, contracts with the leading shipping lines, terminals and overseas agents is a guarantee of high-quality service. "
  },
  {
    title: "Konsultasiya",
    image: KompleksLogistika,
    path: '/beynelxalq-ticaret',
 description: "A membership in FIATA and WCA, contracts with the leading shipping lines, terminals and overseas agents is a guarantee of high-quality service. "
  },
];


const Services = () => {

  const { t, i18n } = useTranslation();
  return (
    <div className="container mx-auto bg-[#F9FAFF] py-24">
      <div className='w-[80%] mx-auto'>
      <div className="text-center w-[60%] mx-auto">
        <h1 className="text-4xl font-bold mb-4">{t("Sizə necə kömək edə bilərik?")}</h1>
        <p className="text-lg text-gray-600">
          {t("Biz nəinki premium loqistik xidmətlər hətta rəqabətə davamlı sərfəli qiymətlər təklif edirik.")}
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-8 w-full mx-auto">
        {transportMethods.map((method, index) => (
          <a href={method.path} key={index} className="relative group">
            <div className="relative max-w-sm rounded overflow-hidden shadow-lg">
              <img className="w-full" src={method.image} alt={method.title} />
              <div className="absolute bottom-0 left-0 w-full bg-white bg-opacity-90 p-4">
                <h3 className="text-black text-lg font-bold">{t(method.title)}</h3>
              </div>
              <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-between p-4">
                <div>
                  <h3 className="text-white text-lg font-bold mb-2">{t(method.title)}</h3>
                  <p className="text-white text-xs">{t(method.description)}</p>
                </div>
                <button className="px-4 py-2 border-2 border-white text-white font-bold rounded">{t("Daha çox")}</button>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>   
    </div>
  );
};

export default Services;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { partners1, partners10, partners2, partners3, partners4, partners5, partners6} from '../../../assets';

const Partners = () => {

  const { t } = useTranslation();
  const logos = [
    { src: partners1, alt: 'Mitsubishi Motors' },
    { src: partners2, alt: 'Volvo' },
    { src: partners3, alt: 'Kia' },
    { src: partners4, alt: 'Sven' },
    { src: partners5, alt: 'Sony' },
    { src: partners6, alt: 'Beko' },
  ];

  return (
    <div className="bg-cover bg-center h-screen flex flex-col items-center justify-center p-8" style={{ backgroundImage: 'url(./airplane.webp)' }}>
      <h1 className="text-white font-bold text-4xl mb-8">{t("Tərəfdaşlarımız")}</h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-8">
        {logos.map((logo, index) => (
          <div key={index} className="flex items-center justify-center p-4 bg-white rounded-lg shadow-md">
            <img src={logo.src} alt={logo.alt} className="max-h-48" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;

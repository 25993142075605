import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import About from "./Pages/Main/About/About";
import Xidmetler from "./Pages/Main/Xidmetler/Xidmetler";
import Contact from "./Pages/Main/Contact/Contact";
import Quruyol from "./Pages/LogisticsServices/Quruyol/Quruyol";
import Havayol from "./Pages/LogisticsServices/Havayol/Havayol";
import Demiryol from "./Pages/LogisticsServices/Demiryol/Demiryol";
import AnbarXidmeti from "./Pages/OtherServices/AnbarXidmeti/AnbarXidmeti";
import GomrukResmi from "./Pages/OtherServices/GomrukResmi/GomrukResmi";
import MultiModal from "./Pages/OtherServices/MultiModal/MultiModal";
import KompleksLogistika from "./Pages/OtherServices/KompleksLogistika/KompleksLogistika";
import TopdanSatis from "./Pages/OtherServices/TopdanSatis/TopdanSatis";
import Denizyol from "./Pages/LogisticsServices/Denizyol/Denizyol";
import BeynelxalqTicaret from "./Pages/LogisticsServices/BeynelxalqTicaret/BeynelxalqTicaret";
import BeyannameTertibi from "./Pages/CustomsClearanceServices/BeyennameTertibi/BeyannameTertibi";
import GomrukXercleri from "./Pages/CustomsClearanceServices/GomrukXercleri/GomrukXercleri";
import ResmilesdirmeIcaze from "./Pages/CustomsClearanceServices/ResmilesdirmeIcaze/ResmilesdirmeIcaze";
import MerkeziGomruk from "./Pages/CustomsClearanceServices/MerkeziGomruk/MerkeziGomruk";
import IstenilenGomruk from "./Pages/CustomsClearanceServices/IstenilenGomruk/IstenilenGomruk";
import OdenissizKonsultasiya from "./Pages/CustomsClearanceServices/OdenissizKonsultasiya/OdenissizKonsultasiya";
import CommonHeader from "./Components/CommonHeader/CommonHeader";
import PageNotFound from "./Pages/Main/PageNotFound/PageNotFound";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Konsultasiya from "./Pages/Konsultasiya/Konsultasiya";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import DashboardContact from './Pages/DashboardContact/DashboardContact'
import DashboardKonsultasiya from "./Pages/DashboardKonsultasiya/DashboardKonsultasiya";
import { ToastContainer } from "react-toastify";



const Layout = () => {
  return (
    <div>
       <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        theme="colored"
      /> 
      <CommonHeader/>
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>

        <Route path="/" element={ <Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/xidmetler" element={ <Xidmetler/>}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/*" element={ <PageNotFound />}></Route>

        <Route path="/quruyol" element={ <Quruyol />}></Route>
        <Route path="/havayol" element={ <Havayol />}></Route> 
        <Route path="/demiryol" element={ <Demiryol />}></Route>
        <Route path="/denizyol" element={ <Denizyol />}></Route>
        <Route path="/beynelxalq-ticaret" element={ <BeynelxalqTicaret/>}></Route>
        <Route path="/odenissiz-konsultasiya" element={ <OdenissizKonsultasiya />}></Route>

        <Route path="/beyannamelerin-tertibi" element={ <BeyannameTertibi/>}></Route>
        <Route path="/gomruk-xercleri" element={ <GomrukXercleri/>}></Route>
        <Route path="/resmilesdirme-icaze" element={ <ResmilesdirmeIcaze/>}></Route>
        <Route path="/merkezi-gomruk" element={ <MerkeziGomruk/>}></Route>
        <Route path="/istenilen-gomruk" element={ <IstenilenGomruk/>}></Route>
        <Route path="/konsultasiya" element={ <Konsultasiya/>}></Route>

        <Route path="/anbar-xidmeti" element={ <AnbarXidmeti />}></Route>
        <Route path="/multimodal" element={ <MultiModal />}></Route>
        <Route path="/gomruk-resmi" element={ <GomrukResmi />}></Route>
        <Route path="/kompleks-logistika" element={ <KompleksLogistika />}></Route>
        <Route path="/topdan-satis" element={ <TopdanSatis />}></Route>
        <Route path="/admin" element={ <AdminPanel />}></Route>
        <Route path="/dashboard/konsultasiya" element={ <DashboardKonsultasiya />}></Route>
        <Route path="/dashboard/contact" element={ <DashboardContact />}></Route>
      </Route>
    </Route>
  )
);

function App() {

  return (
    <div className="font-bodyFont">
     <div className="max-w-[1740px] mx-auto">
     <FloatingWhatsApp phoneNumber='+994502613940' accountName="TradeGroup" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' />
     <RouterProvider router={router} />
     </div>
    </div>
  );
}

export default App;





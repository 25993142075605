import React from 'react';
import Header from '../../../Components/Header/Header';
import { Quru } from '../../../assets';
import { useTranslation } from 'react-i18next';

const MerkeziGomruk = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <Header/>
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Mərkəzi gömrük ekspertizasına müraciət")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Biz beynəlxalq ticarət əməliyyatlarınızın dəqiq və düzgün şəkildə aparılmasını təmin etmək üçün Mərkəzi Gömrük Ekspertizasına müraciət xidmətini də təklif edirik. Bu xidmət çərçivəsində, məhsullarınızın gömrük tələblərinə uyğunluğunu təmin etmək və müvafiq sertifikatları əldə etmək üçün Mərkəzi Gömrük Ekspertizasına müraciət prosesini sizin adınıza idarə edirik. Mütəxəssislərimiz müraciət prosedurunu və lazımi sənədlərin toplanmasını həyata keçirərək, məhsullarınızın gömrükdə gecikmədən və əlavə xərclər olmadan təhvil alınmasını təmin edir. Bu xidmətlə, ticarət əməliyyatlarınızın hər mərhələsində dəqiq və peşəkar dəstək alacaqsınız.")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
    </>
  );
}

export default MerkeziGomruk;

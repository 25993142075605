import React from "react";
import { customClearance } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";

const GomrukResmi = () => {

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Gömrük rəsmiləşdirilməsi")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Beynəlxalq biznes fəaliyyəti uzun müddət, hatta ömür boyu layiqli gəlir gətirən ən effektiv biznes növlərindən biridir. Lakin effektivlik o zaman baş verir ki, xaricdən gətirilən malların gömrük rəsmiləşdirilməsinin təşkili peşəkarlar tərəfindən həyata keçirilsin. Trade Group şirkətimi gömrük rəsmiləşdirilməsi prosedurunun sürətli və 100% uğurlu olmasına zəmanət verir, çünki bizimlə yalnız yüksək ixtisaslı peşəkarlar işləyir. Biz elə yüksək keyfiyyətli xidmətlər təqdim edirik ki, yükünüzün gömrükdə belə gecikməsin! Şirkətimiz yalnız Azərbaycan Respublikasının milli qanunvericiliyi və Gömrük Məcəlləsi çərçivəsində fəaliyyət göstərir, Beynəlxalq Ticarətin həyata keçirilməsini tənzimləyən normativ sənədlərin tələblərinə tam əməl edir. Müasir informasiya texnologiyalarından, o cümlədən internet imkanlarından istifadə edərək malların ölkəmizin ərazisinə idxalı zamanı onların gömrük rəsmiləşdirilməsi xərclərini azaldır və buna görə də şirkətimiz gömrük broker xidmətlərinin göstərilməsi üçün ən sərfəli qiymətləri təklif edir. Bizimlə əlaqə saxlayın, sərhəddə malların emalı prosesində əsl peşəkarlığın nə olduğunu görəcəksiniz.")}
          </p>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
            {t("Gömrük xidmətlərinin qiymətləndirilməsi")}
          </h1>
          <p className="mb-5 text-sm md:text-lg">
            {t("Sərfəli qiymətlər bizim güclü rəqabət üstünlüyümüzdür Biz hər bir müştəriyə fərdi yanaşır və konkret yük və maliyyə vəziyyətindən asılı olaraq çevik qiymətlər təklif edirik")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${customClearance})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center', 
            backgroundRepeat: 'no-repeat'
          }} 
        ></div>
      </div>
    </>
  );
};

export default GomrukResmi;




import React from "react";
import { Service1, Service2, Service3, Service4, Service5, Service6, Service7, Service8 } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";


const Xidmetler = () => {

  const { t } = useTranslation();

  return (
   <>
   <Header/>
  <div className="flex flex-col md:flex-row mt-12 space-y-6 md:space-y-0 md:space-x-6">
  <div className="w-full md:w-1/2">
    <img src={Service1} className="w-full h-auto rounded-lg shadow-md" alt="Service Image" />
  </div>
  <div className="w-full md:w-1/2 flex justify-center items-center py-6 md:py-10">
    <div className="max-w-lg md:max-w-xl p-4 md:p-6 rounded-lg bg-white">
      <h1 className="text-sm font-semibold tracking-wide text-indigo-600 uppercase mb-2 text-center md:text-left">
        {t('TRADE GROUP')}
      </h1>
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 mb-4 text-center md:text-left">
        {t('Quru yük daşımaları')}
      </h2>
      <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6 text-justify">
        {t(
          'Quru yükdaşımaları daha çox yük avtomobilləri vasitəsilə həyata keçirilir. Daşınma zamanı nəinki yükün həcmi, hətta saxlanma şəraiti də mütləq şəkildə nəzərə alınır. Beynəlxalq loqistik şirkətlərlə rəsmi partnyor olan Trade Group şirkəti sizə tam beynəlxalq standartlara uyğun quru yük daşımaları xidməti təklif edir. Bu baxımdan Trade Group şirkəti beynəlxalq nəqliyyat vasitələrindən istifadə etməklə yüklərin daşınması, saxlanması və ekspedisiya xidmətləri sahəsində uzun illər təcrübəsinə malikdir və özünü etibarlı tərəfdaş kimi təsdiq etməyi bacarmışdır...'
        )}
      </p>
      <div className="flex justify-center md:justify-start">
        <a href="/quruyol">
          <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm md:text-lg font-semibold">
            {t('Layihəyə bax')} <span className="ml-2">→</span>
          </button>
        </a>
      </div>
    </div>
  </div>
</div>


<div className="flex flex-col md:flex-row mt-12 space-y-6 md:space-y-0 md:space-x-6">
  <div className="w-full md:w-1/2 flex justify-center items-center py-6 md:py-10">
    <div className="max-w-lg md:max-w-xl p-4 md:p-6 rounded-lg bg-white">
      <h1 className="text-sm font-semibold tracking-wide text-indigo-600 uppercase mb-2 text-center md:text-left">
        {t('TRADE GROUP')}
      </h1>
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 mb-4 text-center md:text-left">
        {t("Hava nəqliyyatı ilə yük daşımaları")}
      </h2>
      <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6 text-justify">
        {t("Yüklərin hava yolu ilə daşınması dünyanın hansı yerindən asılı olmayaraq yük çatdırılmasının ən sürətli və etibarlı yoludur. Bu baxımdan hava nəqliyyatı loqistik xidmətlər arasında ən vacib və tələb olunan xidmətlərdən biri hesab olunur.")}
      </p>
      <div className="flex justify-center md:justify-start">
        <a href="/havayol">
          <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm md:text-lg font-semibold">
            {t('Layihəyə bax')} <span className="ml-2">→</span>
          </button>
        </a>
      </div>
    </div>
  </div>
  <div className="w-full md:w-1/2 flex justify-center md:justify-end">
    <img src={Service2} className="w-full h-auto rounded-lg shadow-md" alt="Service Image" />
  </div>
</div>

<div className="flex flex-col md:flex-row mt-12 space-y-6 md:space-y-0 md:space-x-6">
  <div className="w-full md:w-1/2">
    <img src={Service3} className="w-full h-auto rounded-lg shadow-md" alt="Service Image" />
  </div>
  <div className="w-full md:w-1/2 flex justify-center items-center py-6 md:py-10">
    <div className="max-w-lg md:max-w-xl p-4 md:p-6 rounded-lg bg-white">
      <h1 className="text-sm font-semibold tracking-wide text-indigo-600 uppercase mb-2 text-center md:text-left">
        {t('TRADE GROUP')}
      </h1>
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 mb-4 text-center md:text-left">
        {t("Dəmir yolu nəqliyyatı ilə yük daşınmaları")}
      </h2>
      <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6 text-justify">
        {t("Dəmir yolu nəqliyyatı ilə yük daşınmaları xüsusilə uzun məsafələrə daşınma zamanı yüksək etibarlılıq və təhlükəsizliyi təmin edən ən universal və ucuz daşınma vasitəsidir. Böyük tutumlu vaqonlar, eləcə də müxtəlif platformalar sayəsində daşınma üçün istifadə olunan dəmir yollarında istənilən növ yükləri, o cümlədən iriqabaritli və digər avadanlıqları daşımaq mümkündür...")}
      </p>
      <div className="flex justify-center md:justify-start">
        <a href="/demiryol">
          <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm md:text-lg font-semibold">
            {t('Layihəyə bax')} <span className="ml-2">→</span>
          </button>
        </a>
      </div>
    </div>
  </div>
</div>

<div className="flex flex-col md:flex-row mt-12 space-y-6 md:space-y-0 md:space-x-6">
  <div className="w-full md:w-1/2 flex justify-center items-center py-6 md:py-10">
    <div className="max-w-lg md:max-w-xl p-4 md:p-6 rounded-lg bg-white">
      <h1 className="text-sm font-semibold tracking-wide text-indigo-600 uppercase mb-2 text-center md:text-left">
        {t('TRADE GROUP')}
      </h1>
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 mb-4 text-center md:text-left">
        {t("Dəniz yolu ilə yüklərin daşınması")}
      </h2>
      <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6 text-justify">
        {t("Trade Group şirkəti sizlərə dəniz yolu ilə yüklərin daşınmasın üzrə kompleks həllər təklif edir. Bu baxımdan bizi seçməklə aşağıdakı üstünlüklərə sahib çıxacaqsınız")}
      </p>
      <div className="flex justify-center md:justify-start">
        <a href="/demiryol">
          <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm md:text-lg font-semibold">
            {t('Layihəyə bax')} <span className="ml-2">→</span>
          </button>
        </a>
      </div>
    </div>
  </div>
  <div className="w-full md:w-1/2 flex justify-center md:justify-end">
    <img src={Service4} className="w-full h-auto rounded-lg shadow-md" alt="Service Image" />
  </div>
</div>

<div className="flex flex-col md:flex-row mt-12 space-y-6 md:space-y-0 md:space-x-6">
  <div className="w-full md:w-1/2">
    <img src={Service6} className="w-full h-auto rounded-lg shadow-md" alt="Service Image" />
  </div>
  <div className="w-full md:w-1/2 flex justify-center items-center py-6 md:py-10">
    <div className="max-w-lg md:max-w-xl p-4 md:p-6 rounded-lg bg-white ">
      <h1 className="text-sm font-semibold tracking-wide text-indigo-600 uppercase mb-2 text-center md:text-left">
        {t('TRADE GROUP')}
      </h1>
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 mb-4 text-center md:text-left">
        {t("Gömrük rəsmiləşdirilməsi")}
      </h2>
      <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6 text-justify">
        {t("Beynəlxalq biznes fəaliyyəti uzun müddət, hətta ömür boyu layiqli gəlir gətirən ən effektiv biznes növlərindən biridir. Lakin effekivlik o zaman baş verir ki, xaricdən gətirilən malların gömrük rəsmiləşdirilməsinin təşkili peşəkarlar tərəfindən həyata keçirilsin. Trade Group şirkətimi gömrük rəsmiləşdirilməsi prosedurunun sürətli və 100% uğurlu olmasına zəmanət verir...")}
      </p>
      <div className="flex justify-center md:justify-start">
        <a href="/gomruk-resmi">
          <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm md:text-lg font-semibold">
            {t('Layihəyə bax')} <span className="ml-2">→</span>
          </button>
        </a>
      </div>
    </div>
  </div>
</div>

<div className="flex flex-col md:flex-row mt-12 space-y-6 md:space-y-0 md:space-x-6">
  <div className="w-full md:w-1/2 flex justify-center items-center py-6 md:py-10">
    <div className="max-w-lg md:max-w-xl p-4 md:p-6 rounded-lg bg-white ">
      <h1 className="text-sm font-semibold tracking-wide text-indigo-600 uppercase mb-2 text-center md:text-left">
        {t('TRADE GROUP')}
      </h1>
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 mb-4 text-center md:text-left">
        {t("Multimodal daşımalar")}
      </h2>
      <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6 text-justify">
        {t("Multimodal nəqliyyat bütün nəqliyyat infrastrukturundan istifadə etməklə bir neçə nəqliyyat növündə malların mərhələli şəkildə çatdırılmasıdır. Eyni zamanda, dəniz, çay, dəmir yolu, avtomobil və ya hava yolu ilə daşıma üsulları istənilən kombinasiyada birləşdirilə bilər...")}
      </p>
      <div className="flex justify-center md:justify-start">
        <a href="/multimodal">
          <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm md:text-lg font-semibold">
            {t('Layihəyə bax')} <span className="ml-2">→</span>
          </button>
        </a>
      </div>
    </div>
  </div>
  <div className="w-full md:w-1/2 flex justify-center md:justify-end">
    <img src={Service7} className="w-full h-auto rounded-lg shadow-md" alt="Service Image" />
  </div>
</div>

<div className="flex flex-col md:flex-row mt-12 space-y-6 md:space-y-0 md:space-x-6">
  <div className="w-full md:w-1/2">
    <img src={Service5} className="w-full h-auto rounded-lg shadow-md" alt="Service Image" />
  </div>
  <div className="w-full md:w-1/2 flex justify-center items-center py-6 md:py-10">
    <div className="max-w-lg md:max-w-xl p-4 md:p-6 rounded-lg bg-white">
      <h1 className="text-sm font-semibold tracking-wide text-indigo-600 uppercase mb-2 text-center md:text-left">
        {t('TRADE GROUP')}
      </h1>
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 mb-4 text-center md:text-left">
        {t("Anbar xidməti")}
      </h2>
      <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-6 text-justify">
        {t("Yüklərinizin güvənli və təhlükəsiz şəkildə saxlanılmasını təmin etmək üçün geniş və hər bir standartlara uyğun anbarlarla xidmətinizdəyik. Trade Group şirkəti müasir anbar komplekslərində yüklərin terminal emalını və məsuliyyətli saxlanmasını təklif edir. Anbar saxlanma xidmətinə aşağıdakılar daxildir...")}
      </p>
      <div className="flex justify-center md:justify-start">
        <a href="/anbar-xidmeti">
          <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm md:text-lg font-semibold">
            {t('Layihəyə bax')} <span className="ml-2">→</span>
          </button>
        </a>
      </div>
    </div>
  </div>
</div>

   </>

  );
};

export default Xidmetler;

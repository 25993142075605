import React, { useEffect, useState } from "react";
import { Anbar, azflag, Demir, Deniz, engflag, Gomruk, Hava, KompleksLogistika, Multimodal, Quru, rusflag } from '../../assets/index'
import { useLocation} from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";


const languageOptions = [
  { value: "en", label: "English", flag: engflag },
  { value: "az", label: "Azərbaycan", flag: azflag },
  { value: "rus", label: "Russian", flag: rusflag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation },
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

const Header = () => {
  let params = useLocation()
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));
  const [bgImage, setBgImage] = useState(Quru)
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isServicesExpanded, setIsServicesExpanded] = useState(false);
  const [firstText, setFirstText] = useState('')
  const [secondText, setSecondText] = useState('')

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);


  
  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const toggleServices = () => {
    setIsServicesExpanded(!isServicesExpanded);
  };


useEffect(() => {
  if(params.pathname === '/about') {
  setFirstText('')
  setSecondText(t("Haqqımızda"))

  }
  else if(params.pathname === '/') {
    setFirstText('')
    setSecondText(t("Əsas"))
    }
    else if(params.pathname === '/xidmetler') {
      setFirstText('')
    setSecondText(t("Xidmətlərimiz"))
      }
  else if(params.pathname === '/contact') {
    setFirstText('')
    setSecondText(t("Əlaqə"))
    }
  else if(params.pathname === '/quruyol') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Quru yolu ilə yük daşımaları"))
    setBgImage(Quru)
  }
  else if(params.pathname === '/havayol') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Hava nəqliyyatı ilə yük daşımaları"))
    setBgImage(Hava)
  }
  else if(params.pathname === '/demiryol') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Dəmiryolu nəqliyyatı ilə yük daşımaları"))
    setBgImage(Demir)
  }
  else if(params.pathname === '/denizyol') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Dəniz yolu ilə yüklərin daşınması"))
    setBgImage(Deniz)
  }
  else if(params.pathname === '/anbar-xidmeti') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Anbar xidməti"))
    setBgImage(Anbar)
  }
  else if(params.pathname === '/multimodal') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Multimodal daşımalar"))
    setBgImage(Multimodal)
  }
  else if(params.pathname === '/kompleks-logistika') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Kompleks logistika"))
    setBgImage(KompleksLogistika)
  }
  else if(params.pathname === '/gomruk-resmi') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Gömrük rəsmiləşdirilməsi"))
    setBgImage(Gomruk)
  }
  else if(params.pathname === '/topdan-satis') {
    setFirstText(t("Xidmətlər"))
    setSecondText(t("Topdan satış"))
  }
}, [])




  return (
    <>
   
    <div
        className={` fixed top-0 right-0 z-50 flex flex-col items-center justify-between bg-[#124364] text-white w-1/4 h-full transform transition-transform duration-300 ${
          isPanelOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button
          className="absolute top-4 right-4 text-white"
          onClick={togglePanel}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        <nav className="flex flex-col space-y-4 mt-20 text-center w-full ">
          <a href="/" className="text-xl hover:bg-teal-400 block w-full p-2 ">Trade Group</a>
          <a href="/about" className="text-xl hover:bg-teal-400 block w-full p-2">{t("Haqqımızda")}</a>
          <div  onClick={toggleServices} className="relative w-full">
            <a href="#" className="text-xl hover:bg-teal-400 block w-full p-2">{t("Xidmətlərimiz")}  {isServicesExpanded ? "-" : "+"}</a>
            {isServicesExpanded && (
              <div className="flex flex-col mt-2 space-y-2">
                <a href="/quruyol" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Quru yük daşımalar")}</a>
                <a href="/havayol" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Hava nəqliyyatı")}</a>
                <a href="/demiryol" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Dəmir yolu nəqliyyatı")}</a>
                <a href="/denizyol" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Dəniz nəqliyyatı")}</a>
                <a href="anbar-xidmeti" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Anbar xidməti")}</a>
                <a href="gomruk-resmi" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Gömrük rəsmiləşdirməsi")}</a>
                <a href="multimodal" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Multimodal daşımalar")}</a>
                <a href="kompleks-logistika" className="text-lg hover:bg-teal-400 block w-full p-2">{t("Kompleks logistika")}</a>
              </div>
            )}
          </div>
          <a href="/contact" className="text-xl hover:bg-teal-400 block w-full p-2">{t("Əlaqə")}</a>
          <div className="mt-6">
            <p className="text-lg">{t("Bizə təklifiniz var?")}</p>
           <a href="/contact"> <button className="mt-2 px-8 py-2 bg-teal-500 text-white rounded-full">
              {t("Bizə yazın")}
            </button></a>
          </div>
          <div className="flex space-x-4 mt-6 mx-auto">
            <a href="#"><i className="fab fa-facebook-f"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-linkedin-in"></i></a>
          </div>
        </nav>
        <p className="mt-6 mb-6">Narimanov metro st, Baku</p>
      </div>
      
</>
  );
};

export default Header;





















import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {

  const { t, i18n } = useTranslation();
  
  return (
<>
<div className="mt-8 bg-white rounded-lg shadow-lg text-center flex flex-col lg:flex-row justify-around border-t-[3px] border-teal-400 py-4">
  <div className="flex flex-col items-center w-full lg:w-1/2 mb-4 lg:mb-0">
    <div className="text-[18px] font-bold mb-4 text-[#124364] text-center lg:text-left">
      {t("Loqistik xidmətə ehtiyacınız var?")}
    </div>
    <div>
      <a href="/contact">
        <button className="bg-teal-500 text-white px-4 py-2 rounded-full">
          {t("Bizə yazın")} →
        </button>
      </a>
    </div>
  </div>
  <div className="flex flex-col items-center w-full lg:w-1/2">
    <div className="text-[18px] font-bold mb-4 text-[#124364] text-center lg:text-left">
      {t("Gömrük rəsmiləşdirilməsinə ehtiyacınız var?")}
    </div>
    <div>
      <a href="/contact">
        <button className="bg-teal-500 text-white px-4 py-2 rounded-full">
          {t("Bizə yazın")} →
        </button>
      </a>
    </div>
  </div>
</div>





<footer className="text-white">
  <div className="flex flex-col lg:flex-row justify-between">
    <div className="mb-8 lg:mb-0 text-center lg:text-left bg-teal-600 lg:w-1/2 w-full py-8">
      <div className="w-3/4 lg:w-1/2 mx-auto">
        <h2 className="text-lg font-semibold mb-4">{t("Trade Group xidmətlərinə")}</h2>
        <h2 className="text-5xl font-semibold mb-4">{t("qoşulmağa")}</h2>
        <h2 className="text-5xl font-semibold mb-4">{t("hazırsınızmı?")}</h2>

        <a href="https://api.whatsapp.com/send?phone=994502613940&text=">
          <button className="flex items-center justify-center lg:justify-start mx-auto lg:mx-0 space-x-2 px-4 py-2 border rounded-full border-teal-500">
            {t("Əməkdaşlığa başla")}
            <span className="ml-2">→</span>
          </button>
        </a>
      </div>
    </div>
    <div className="lg:w-1/2 w-full bg-white flex flex-col justify-end">
      <div className="bg-[#124364] flex flex-col lg:flex-row justify-between items-center h-auto lg:h-[220px] p-8 lg:p-0">
        <div className="w-full lg:w-5/6 mx-auto flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:mr-16 text-center lg:text-left">
            <h3 className="text-lg font-semibold mb-4">{t("Faydalı linklər")}</h3>
            <ul>
              <li><a href="#" className="hover:underline">{t("Kompleks logistika")}</a></li>
              <li><a href="#" className="hover:underline">{t("Gömrük rəsmiləşdirilməsi")}</a></li>
              <li><a href="#" className="hover:underline">{t("Multimodal daşımalar")}</a></li>
            </ul>
          </div>
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h3 className="text-lg font-semibold mb-4">{t("Əlaqə")}</h3>
            <p>{t("Telefon")}: +99450 261 39 40</p>
            <p>Email: info@tradegroup.az</p>
            <div className="flex justify-center lg:justify-start space-x-4 mt-8">
              <a href="#" className="hover:underline"><i className="fab fa-instagram"></i></a>
              <a href="#" className="hover:underline"><i className="fab fa-facebook"></i></a>
              <a href="#" className="hover:underline"><i className="fab fa-twitter"></i></a>
              <a href="#" className="hover:underline"><i className="fab fa-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>



</>
  );
};

export default Footer;

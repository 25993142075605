import React from 'react';
import Awards from './Awards/Awards';
import Branches from './Branches/Branches';
import Services from './Services/Services';
import Carousel from '../../Components/Carousel/Carousel';
import ContactForm from './ContactForm/ContactForm';
import Partners from './Partners/Partners';
import Map from './Map/Map';
import HomeHeader from '../../Components/HomeHeader/HomeHeader';




const Home = () => {
  return (
    <>
    <Carousel/>
    <Services/>
    <Awards/>
    <Map/>
    {/* <ContactForm/> */}
    <Partners/>
    </>
  )
}

export default Home;

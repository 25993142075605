import React from "react";
import { Quru } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";

const About = () => {

  const { t } = useTranslation();

  return (
    <>
      <Header/>
      <div className="border-t-[30px] border-[#124364]"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center ">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Peşəkar loqistika və gömrük rəsmiləşdirilməsi xidmətləri üçün ixtisaslaşmış heyət")}
            </h1>
          </div>
          <p className="mb-5 text-[18px]">
            {t("Ölkə daxilində eləcə də ölkə xaricində etibarlı və güvənli loqistika xidmətləri ilə hər zaman yanınızdayıq...")}
          </p>
          <ul className="list-disc list-inside">
  <li className="text-[18px]">{t("Hava, su, quru, dəmir yolu ilə yüklərin daşınması")}</li>
  <li className="text-[18px]">{t("Təhlükəli yüklərin daşınması")}</li>
  <li className="text-[18px]">{t("Həcmli və qabarıltılı yüklərin daşınması")}</li>
  <li className="text-[18px]">{t("Multimodal və intermodal daşımaları")}</li>
  <li className="text-[18px]">{t("Loqistik planlama")}</li>
  <li className="text-[18px]">{t("Loqistik konsultasiya")}</li>
  <li className="text-[18px]">{t("Topdan satış")}</li>
</ul>

        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }} 
        ></div>
      </div>
      <div className="border-b-[30px] border-[#124364]"></div>
      <div className="flex flex-col-reverse md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
      </div>
    </>
  );
};

export default About;




import React, { useEffect, useState } from "react";
import { Quru } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";


const KompleksLogistika = () => {

  const { t } = useTranslation();

  return (
    <>
    <Header/>
    <div className="border-t-[30px] border-teal-400"></div>
       <div className="flex">
         <div className='w-1/12 bg-white'>
         </div>
         <div className="w-6/12 p-10 flex flex-col justify-center">
           <div className="flex items-center mb-5">
             <div className='h-16 w-16 bg-teal-400 mr-4'></div>
             <h1 className="text-3xl font-extrabold mb-5 text-[#124364]">{t("Kompleks loqistika")}</h1>
           </div>
           <h1 className="text-3xl font-extrabold mb-5 text-[#124364]">{t("Trade Group şirkəti loqistika sahəsində hərtərəfli xidmətlər göstərir. Bizim hərtərəfli loqistik xidmətlərimizə daxildir:")}</h1>
           <ul className="list-disc list-inside">
             <li>{t("Gömrük rəsmiləşdirilməsi")}</li>
             <li>{t("Sığortalanma")}</li>
             <li>{t("Bütün loqistik sənədləşmə işləri")}</li>
             <li>{t("Sertifikatların alınması")}</li>
             <li>{t("Sənədlərin xaricə çatdırılması")}</li>
             <li>{t("Loqistik kuryer xidmətləri")}</li>
             <li>{t("Nəqliyyat loqistikası")}</li>
             <li>{t("Beynəlxalq nəqliyyat xidmətləri və s.")}</li>
           </ul> <br/>
           <p>{t("Azərbaycanda biznesin əsas problemlərindən biri loqistikadır. Çox vaxt müəssisələrin uğurunun vacib meyarlarından biri malların idxal və ixracının keyfiyyətli və vaxtında həyata keçirilməsidir. Bu problemlərə çarə axtarırsınızsa, sizi bizimlə əməkdaşlığa dəvət edirik.")}</p>  <br/>
          
           <h1 className="text-3xl font-extrabold mb-5 text-[#124364]">{t("Trade Group şirkəti olaraq bizim iri nəqliyyat vasitələrimiz var və aşağıdakı yük növlərini daşıyırıq:")}</h1>
           <ul className="list-disc list-inside">
             <li>{t("Tibbi avadanlıq və dərmanlar")}</li>
             <li>{t("Toplu tikinti materialları")}</li>
             <li>{t("İstehsal avadanlıqları")}</li>
             <li>{t("Müxtəlif kateqoriyalı təhlükəli yüklər")}</li>
             <li>{t("Qida məhsulları")}</li>
             <li>{t("Müəyyən bir temperatur tələb edən yüklər")}</li>
             <li>{t("Maye yükləri")}</li>
             <li>{t("Və digər yük növləri")}</li>
           </ul> <br/>
           <p>{t("Maddi-texniki bazamız və peşəkar mütəxəssislərimizin mövcudluğu loqistika fəaliyyətində risklərimizi əhəmiyyətli dərəcədə azaldır. O baxımdan çəkinmədən öz biznesinizi bizə etibar edə bilərsiniz.")}</p>
           

         </div>
         <div 
           className="w-5/12 bg-cover bg-center"
           style={{ 
             background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
             backgroundSize: 'cover',
             backgroundPosition: 'center' 
           }} 
         ></div>
       </div>
       </>

  );
};

export default KompleksLogistika;
import React from "react";
import { Quru } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";

const Quruyol = () => {

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Quru yolu ilə yük daşımalar")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Quru yükdaşımaları daha çox yük avtomobilləri vasitəsilə həyata keçirilir. Daşınma zamanı nəinki yükün həcmi, hətta saxlanma şərati də mütləq şəkildə nəzərə alınır. Beynəlxalq loqistik şirkətlərlə rəsmi partnyor olan Trade Group şirkəti sizə tam beynəlxalq standartlara uyğun quru yük daşımaları xidməti təklif edir. Bu baxımdan Trade Group şirkəti beynəlxalq nəqliyyat vasitələrindən istifadə etməklə yüklərin daşınması, saxlanması və ekspedisiya xidmətləri sahəsində uzun illər təcrübəsinə malikdir və özünü etibarlı tərəfdaş kimi təsdiq etməyi bacarmışdır.")}
          </p>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
            {t("Trade Group şirkəti quru vasitəsi ilə istənilən formatda yük daşima xidmətlərini həyata keçirir")}
          </h1>
          <ul className="list-disc list-inside text-sm md:text-lg">
          <li className="text-[17px]">{t("Ümumi yüklərin daşınması")}</li>
          <li className="text-[17px]">{t("Konteyner yüklərin daşınması")}</li>
  <li className="text-[17px]">{t("Qabaritə az olan yüklərin daşınması")}</li>
  <li className="text-[17px]">{t("Ağır çəkili yüklərin daşınması")}</li>
  <li className="text-[17px]">{t("Temperatura asılı olan yüklərin daşınması")}</li>
  <li className="text-[17px]">{t("'Qapıdan qapıya' yük daşima xidməti")}</li>
  <li className="text-[17px]">{t("Daxili yük daşima xidməti")}</li>
  <li className="text-[17px]">{t("Beynəlxalq yük daşima xidməti")}</li>
          </ul>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364] mt-4">
            {t("Nəqliyyat vasitələrində malların daşınma marşrutları")}
          </h1>
          <p className="text-sm md:text-lg">
            {t("Trade Group şirkəti Avropa (Almaniya, İtaliya, Fransa və s.), Asiya (Çin, Tayvan və s. yüklər), MDB (Rusiya, Belarusiya və s.) ölkələri arasında yüklərin avtomojnavt vasitələri ilə çatdırılmasını və ticarət əlaqələrinin təmin edilməsi üzrə ixtisaslaşmışdır. Bütün bunlarla yanaşı Trade Group şirkəti olaraq həmçinin quru yük daşımaları zamanı tələb olunan hüquqi və gömrük sənədlərinin hazırlanmasında da tam şəkildə müştərilərimizə dəstək göstəririk. Eyni zamanda konsalting xidmətimizdən də yararlanmaqla öncədən quru üzərindən en effektiv yol xəritənizi də tərtib edə bilərsiniz.")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
    </>
  );
};

export default Quruyol;


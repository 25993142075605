import React from "react";
import { Quru } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";

const Demiryol = () => {

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Dəmir yolu nəqliyyatı ilə yük daşınmaları")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Dəmir yolu nəqliyyatı ilə yük daşınmaları küsusilə uzun məsafələrə daşınma zamanı yüksək etibarlılığı və təhlükəsizliyi təmin edən ən universal və ucuz daşınma vasitəsidir. Böyük tutumlu vaqonlar, eləcə də müxtəlit platformalar sayəsində daşınma üçün istifadə olunan dəmir yollarında istənilən növ yükləri, o cümlədən iriçaplı və digər avadanlıqları daşımaq mümkündür.")}
          </p>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
            {t("Dəmir yolu nəqliyyatı ilə yüklərin daşınmasının əsas üstün cəhətləri bunlardır:")}
          </h1>
          <ul className="list-disc list-inside text-sm md:text-base">
            <li className="text-[17px]">{t("Yük izləmlərinin müntəzəmliyi")}</li>
            <li className="text-[17px]">{t("Təhlükəsiz və effektiv yük daşımaları")}</li>
            <li className="text-[17px]">{t("Yüksək və stabil sürət")}</li>
            <li className="text-[17px]">{t("Qabaritdən asılı olmayaraq istənilən yüklərin daşınması")}</li>
          </ul>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364] mt-4">
            {t("Dəmir yolu nəqliyyatı ilə yük daşınmasında Trade Group şirkəti tərəfindən göstərilən xidmətlər çeçidinə aşağıdakılar daxildir:")}
          </h1>
          <ul className="list-disc list-inside text-sm md:text-base">
            <li className="text-[17px]">{t("Müştərilərə nəqliyyat vasitələrinin seçilməsi və müqavilə tələbləri ilə bağlı məsləhətlərin verilməsi")}</li>
            <li className="text-[17px]">{t("Beynəlxalq standartlara uyğun rəsmiləşdirmə, müşayiət və gömrük sənədləri")}</li>
            <li className="text-[17px]">{t("Yüklərin vaqonlara yüklənməsi və boşaldılması, onların bərkidilməsi və saxlanması üzrə çərçivə və sxemlərin işlenib hazırlanması, habelə dəmir yolu idarəsi ilə razılaşdırılması")}</li>
            <li className="text-[17px]">{t("Yüklərin daşınması üçün lazım olan sənədlərin hazırlanması")}</li>
            <li className="text-[17px]">{t("Marşrut üzrə yüklərin hərəkətinə nəzarət etmək və bütün lazımi məlumatların, o cümlədən yükün çatma tarixi və vaxtı barədə sifarişçiyə məlumat verilməsi")}</li>
          </ul>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364] mt-4">
            {t("Dəmir yolu nəqliyyatının qiyməti")}
          </h1>
          <p className="mb-5 text-sm md:text-lg">
            {t("Dəmir yolu ilə malların çatdırılması xidmətlərinin dəyəri hər bir konkret hal və müştəri üçün fərdi olaraq hesablanır. Bu, bir çox amillərdən, məsələn, yükün məsafəsi, istiqaməti, növü, çəkisi və çəkisi, xüsusilə tərkibi olunmuş, yükdaşıma platformalarının ehtiyacı, əlavə xərclər və s. Bu qayda olaraq, dəmir yolu nəqliyyatı tarifləri aşağıdakı əsas komponentlərdən ibarətdir:")}
          </p>
          <ul className="list-disc list-inside text-sm md:text-base">
            <li className="text-[17px]">{t("Avtomobillə qatar stansiyasına çatdırılma dəyəri")}</li>
            <li className="text-[17px]">{t("Qatar yükdaşmasının dəyəri")}</li>
            <li className="text-[17px]">{t("Konteynerlərin, yük maşınlarının, sənədlərin, bunların yüklənməsi və boşaldılması dəyəri")}</li>
          </ul>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364] mt-4">
            {t("Dəmir yolu yüklərinin coğrafiyası")}
          </h1>
          <p className="mb-5 text-sm md:text-lg">
            {t("Trade Group şirkəti dəmir daşınma xidmətləri ilə yanaşı olaraq dəmir yolu vasitəsilə də yüklərin çatdırılmasını təmin edərək yüklərin Azərbaycana çatması və yenə də birbaşa daşınmasını həyata keçirir.")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
    </>
  );
};

export default Demiryol;

import React from "react";
import { warehouse } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";

const AnbarXidmeti = () => {

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Anbar xidməti")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Yüklərinizin güvənli və təhlükəsiz şəkildə saxlanılmasını təmin etmək üçün geniş və hər bir standartlara uyğun anbarlarla xidmətinizdəyk. Trade Group şirkəti müasir anbar komplekslərində yüklərin terminal emalını və məsuliyyətli saxlanmasını təklif edir. Anbar saxlanma xidmətinə aşağıdakılar daxildir:")}
          </p>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
            {t("Anbar saxlanma xidmətinə aşağıdakılar daxildir:")}
          </h1>
          <ul className="list-disc list-inside text-sm md:text-base">
            <li className="text-[17px]">{t("Yük üçün anbarda yer təyin edilməsi")}</li>
            <li className="text-[17px]">{t("Yükün saxlanılması üçün ən optimal şəraitin müəyyənləşdirilməsi")}</li>
            <li className="text-[17px]">{t("7/24 anbar və mühafizə xidməti")}</li>
            <li className="text-[17px]">{t("Terminal emalı")}</li>
            <li className="text-[17px]">{t("Saxlanma müddəti bitdikdən sonra yükün ünvana çatdırılması")}</li>
          </ul>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${warehouse})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center'  
          }} 
        ></div>
      </div>
    </>
  );
};

export default AnbarXidmeti;




import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { azflag, engflag, flag, Quru, rusflag, map } from '../../../assets/index'
import { CiMail } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";


const Contact = () => {

  const { t } = useTranslation();

  const navigate = useNavigate()

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientBudget, setclientBudget] = useState("");  // Yeni state
  const [clientMessage, setclientMessage] = useState("");


  const handleSubmitContact = async (e) => {
    e.preventDefault();
  
    let inputs = { 
      name: clientName, 
      email: clientEmail, 
      phone: clientPhone, 
      message: clientMessage, 
      budget: clientBudget   // Budget bilgisi eklendi
    };
    
    
    try {
      console.log(inputs);
      
      // Seçilen ürünleri post isteği ile gönder
      const response = await axios.post("https://www.tradegroup.az/tradegroup/contact.php", inputs);
      
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Form göndərildi");
      
      // Clear form after successful submission
      setclientName('');
      setclientEmail('');
      setclientPhone('');
      setclientMessage('');
      
      navigate('/');
      
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
      toast.error("Form göndərilərkən xəta baş verdi");
    }
  };
  

  return (
    <>
    <Header/>
     <div className="p-8 min-h-screen">
     <div className="max-w-5xl mx-auto bg-white p-4 md:p-6 lg:p-8 rounded-md">
  <div className="flex flex-col md:flex-row justify-between items-start md:items-start md:space-x-6">
    <div className="w-full md:w-1/3 mb-6 md:mb-0">
      <div className="flex items-center mb-4 md:mb-6">
        <div className="border-l-[4px] border-teal-400 h-6 mr-2"></div>
        <h2 className="text-xl md:text-2xl font-semibold">{t("Əlaqə vasitələri")}</h2>
      </div>
      <div className="space-y-4 md:space-y-6">
        <div className="bg-teal-500 text-white p-4 md:p-6 rounded-md flex items-center space-x-4">
          <FaWhatsapp size={20} className="md:size-24" />
          <div>
            <h3 className="text-md md:text-lg font-semibold mb-1 md:mb-2">{t("Bizə zəng edin")}</h3>
            <p className="text-sm md:text-base mb-1">+994 50 261 39 40</p>
            <p className="text-sm md:text-base">+994 50 226 80 79</p>
          </div>
        </div>
        <div className="bg-teal-500 text-white p-4 md:p-6 rounded-md flex items-center space-x-4">
          <CiMail size={20} className="md:size-24" />
          <div>
            <h3 className="text-md md:text-lg font-semibold mb-1 md:mb-2">{t("Bizə yazın")}</h3>
            <p className="text-sm md:text-base mb-1">info@tradegroup.az</p>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-2/3"> 
      <div className="flex items-center mb-4 md:mb-6">
        <div className="border-l-[4px] border-teal-400 h-6 mr-2"></div>
        <h2 className="text-xl md:text-2xl font-semibold">{t("Bizə müraciət edin")}</h2>
      </div>
      <form onSubmit={handleSubmitContact} className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <input
        onChange={(event) => setclientName(event.target.value)}
        value={clientName}
        required
          type="text"
          placeholder={t("Adınız")}
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <input
        onChange={(event) => setclientEmail(event.target.value)}
        value={clientEmail}
        required 
          type="email"
          placeholder={t("Email")}
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <input
        onChange={(event) => setclientPhone(event.target.value)}
        value={clientPhone}
        required
          type="number"
          placeholder={t("Telefon")}
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <input
  onChange={(event) => setclientBudget(event.target.value)}  // Yeni onChange
  value={clientBudget}  // Value state'e bağlı
  required
  type="text"
  placeholder={t("Büdcə")}
  className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
/>
        <textarea
        onChange={(event) => setclientMessage(event.target.value)}
        value={clientMessage}
        required
          placeholder={t("İstək")}
          className="p-2 border border-gray-300 rounded-md col-span-1 md:col-span-2 h-32 w-full text-sm md:text-base resize-none"
        ></textarea>
        <button
          type="submit"
          className="col-span-1 md:col-span-2 bg-teal-500 text-white p-2 md:p-3 rounded-md w-full text-sm md:text-base font-semibold"
        >
          {t("Göndər")}
        </button>
      </form>
    </div>
  </div>
</div>



      <div  style={{ backgroundImage: `url(${map})` }}  className="h-full w-full bg-teal-400 flex items-center justify-center mt-24">
        <div className="text-4xl font-bold ml-4">{t("Biz burdayıq")}</div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3038.3096188936693!2d49.873445876010265!3d40.40199127144197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDI0JzA3LjIiTiA0OcKwNTInMzMuNyJF!5e0!3m2!1sen!2saz!4v1723187413066!5m2!1sen!2saz" width='1400' height='150'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      
      </div>
    </div>
    </>

  );
};

export default Contact;

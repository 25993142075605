import React from "react";
import { Quru } from '../../../assets/index';
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";

const Denizyol = () => {

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="border-t-[30px] border-teal-400"></div>
      <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
          <div className="flex items-center mb-5">
            <div className='h-12 w-12 md:h-16 md:w-16 bg-teal-400 mr-4'></div>
            <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
              {t("Dəniz yolu ilə yüklərin daşınması")}
            </h1>
          </div>
          <p className="mb-5 text-sm md:text-lg">
            {t("Trade Group şirkəti sizlərə dəniz yolu ilə yüklərin daşınması üzrə kompleks həllər təklif edir.")}
          </p>
          <h1 className="text-2xl md:text-3xl font-extrabold mb-5 text-[#124364]">
            {t("Bizi seçməklə aşağıdakı üstünlüklərə sahib çıxacaqsınız:")}
          </h1>
          <ul className="list-disc list-inside text-sm md:text-base">
            <li className="text-[17px]">{t("Trade Group olaraq beynəlxalq dəniz daşımaları şirkətləri ilə partnyorluq müqavilələrimiz sizə həmin şirkətlərin xidmətlərindən bizim vasitəmizlə birbaşa istifadə etmək imkanı verəcək")}</li>
            <li className="text-[17px]">{t("Hüquq və sənədlaşma baxımından şirkət olaraq daim yanınızda olacağıq")}</li>
            <li className="text-[17px]">{t("Çatdırılma prosesinə tam şəkildə nəzarət edən personal menecerlə sahib olacaqsınız")}</li>
            <li className="text-[17px]">{t("Yüklərin sığortalanması və gömrük rəsmiləşdirilməsi üzrə tam dəstək")}</li>
            <li className="text-[17px]">{t("İstənilən ölçüdə və qabaritdə olan yüklərin, hətta təhlükəli yüklərin dəniz yolu ilə daşınıb çatdırılması")}</li>
          </ul> 
          <br/>
          <p className="text-sm md:text-lg">
            {t("Biz həmişə ən sərfəli qiymətlərlə yüklərinizin dəniz yolu ilə çatdırılması ilə yanaşı, onların tamlığının qorunmasına da daim nəzarət edirik. Çünki Trade Group şirkəti olaraq bu baxımdan biz mümkün olan bütün icazə, lisenziya və sertifikatlara malikik.")}
          </p>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
    </>
  );
};

export default Denizyol;

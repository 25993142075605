import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CiMail } from 'react-icons/ci';
import { FaWhatsapp } from 'react-icons/fa6';
import { map } from '../../assets';
import Header from '../../Components/Header/Header';
import { Quru } from '../../assets';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Konsultasiya = () => {

  const { t } = useTranslation();

  const navigate = useNavigate()

  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientBudget, setclientBudget] = useState("");  // Yeni state
  const [clientMessage, setclientMessage] = useState("");


  const handleSubmitContact = async (e) => {
    e.preventDefault();
  
    let inputs = { 
      name: clientName, 
      email: clientEmail, 
      phone: clientPhone, 
      message: clientMessage, 
      budget: clientBudget   // Budget bilgisi eklendi
    };
    
    
    try {
      console.log(inputs);
      
      // Seçilen ürünleri post isteği ile gönder
      const response = await axios.post("https://www.tradegroup.az/tradegroup/konsultasiya.php", inputs);
      
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Form göndərildi");
      
      // Clear form after successful submission
      setclientName('');
      setclientEmail('');
      setclientPhone('');
      setclientMessage('');
      
      navigate('/');
      
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
      toast.error("Form göndərilərkən xəta baş verdi");
    }
  };
  
  return (
   <>
   <Header/>
    <div className="flex flex-col md:flex-row">
        <div className='w-full md:w-1/12 bg-white'>
        </div>
        <div className="w-full md:w-6/12 p-5 md:p-10 flex flex-col justify-center">
        <div className="flex items-center mb-4 md:mb-6">
        <div className="border-l-[4px] border-teal-400 h-6 mr-2"></div>
        <h2 className="text-xl md:text-2xl font-semibold">{t("Ödənişsiz konsultasiya")}</h2>
      </div>
        <form onSubmit={handleSubmitContact} className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <input
        onChange={(event) => setclientName(event.target.value)}
        value={clientName}
        required
          type="text"
          placeholder={t("Adınız")}
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <input
        onChange={(event) => setclientEmail(event.target.value)}
        value={clientEmail}
        required 
          type="email"
          placeholder={t("Email")}
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <input
        onChange={(event) => setclientPhone(event.target.value)}
        value={clientPhone}
        required
          type="number"
          placeholder={t("Telefon")} 
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <input
         onChange={(event) => setclientBudget(event.target.value)}  // Yeni onChange
         value={clientBudget}  // Value state'e bağlı
         required
          type="text"
          placeholder={t("Büdcə")}
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <textarea
                onChange={(event) => setclientMessage(event.target.value)}
                value={clientMessage}
                required
          placeholder={t("İstək")}
          className="p-2 border border-gray-300 rounded-md col-span-1 md:col-span-2 h-32 w-full text-sm md:text-base resize-none"
        ></textarea>
        <button
          type="submit"
          className="col-span-1 md:col-span-2 bg-teal-500 text-white p-2 md:p-3 rounded-md w-full text-sm md:text-base font-semibold"
        >
          {t("Göndər")}
        </button>
      </form>
        </div>
        <div 
          className="w-full md:w-5/12 h-64 md:h-auto bg-cover bg-center"
          style={{ 
            background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), url(${Quru})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
          }} 
        ></div>
      </div>
   </>
  );
};

export default Konsultasiya;

import React from 'react';
import { awards2, awards3, awards4, awards5 } from '../../../assets';
import { useTranslation } from 'react-i18next';

const data = [
  { value: 5, label: 'YEARS OF EXPERIENCE', description: 'we know all the subtleties of freight forwarding.', img: awards2 },
  { value: 2, label: 'BRANCHES', description: 'are enough to declare of our proximity to the client.', img: awards3 },
  { value: '15+', label: 'SPECIALISTS', description: 'fulfill multimodal transportation of 70,000 containers annually.', img: awards5 },
];

const CompanyNumbers = () => {

  const { t, i18n } = useTranslation();
  return (
    <div className="relative bg-cover bg-center h-[400px]" style={{ backgroundImage: 'url(./download.jpg)' }}>
    <div className="absolute inset-0 bg-blue-900 opacity-70"></div> {/* Dark overlay */}
    <div className="relative w-[90%] lg:w-[80%] mx-auto py-10 lg:py-20">
      <div className="container mx-auto text-center mt-10">
        <h2 className="text-3xl sm:text-4xl font-bold mb-8 lg:mb-12 text-white">
          {t("Uğurlarımız Rəqəmlə")}
        </h2>
        <div className="flex flex-wrap justify-center">
          {data.map((item, index) => (
            <div key={index} className='mr-10'>
              <div className="relative bg-transparent mx-auto flex items-center justify-center mb-4">
                <div className="text-5xl sm:text-6xl lg:text-7xl font-bold text-white z-10">
                  {item.value}
                </div>
              </div>
              <h3 className="text-lg sm:text-xl font-semibold mb-2 text-white">{t(item.label)}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  );
};

export default CompanyNumbers;
